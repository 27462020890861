<template>
  <div id="payment-list">
    <!-- <v-card class="mb-8">
      <v-card-title>
        {{ t('Fanaty Payments') }}
      </v-card-title>

      <v-row class="px-2 ma-0 pb-2 text-center">
        <v-col
          cols="12"
          sm="2"
        >
          <v-text-field
            v-model="searchQuery"
            single-line
            dense
            outlined
            hide-details
            :placeholder="t('search')"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card> -->

    <v-card>
      <v-card-title>
        {{ t('tooltip.filters') }}
      </v-card-title>

      <v-row class="px-2 ma-0 text-center">
        <v-col
          cols="12"
          sm=""
        >
          <v-select
            v-model="statusFilter"
            :items="statusOptions"
            item-text="text"
            item-value="value"
            single-line
            outlined
            dense
            hide-details
            clearable
            :placeholder="t('select.status')"
            @change="updateFilter('views-fanaty-payment-list', 'status', statusFilter)"
          ></v-select>
        </v-col>

        <v-col
          cols="12"
          sm="3"
        >
          <v-menu
            v-model="modalFrom"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateFrom"
                :placeholder="t('transactions.from_date')"
                :prepend-icon="icons.mdiCalendar"
                outlined
                readonly
                dense
                clearable
                class="invoice-list-status"
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateFrom"
              color="primary"
              :max="dateTo ? dateTo : maxDate"
              @input="modalFrom = false"
              @change="updateFilter('views-fanaty-payment-list', 'dateFrom', dateFrom)"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col
          cols="12"
          sm="2"
        >
          <v-menu
            v-model="modalTo"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateTo"
                :placeholder="t('transactions.to_date')"
                :prepend-icon="icons.mdiCalendar"
                outlined
                readonly
                dense
                clearable
                class="invoice-list-status"
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateTo"
              color="primary"
              :max="maxDate"
              :min="dateFrom"
              @input="modalTo = false"
              @change="updateFilter('views-fanaty-payment-list', 'dateTo', dateTo)"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-divider />

      <v-row class="px-2 ma-0 text-center">
        <v-col
          cols="12"
          sm="12"
          md="3"
        >
          <v-text-field
            v-model="searchQuery"
            :placeholder="t('search')"
            outlined
            hide-details
            dense
          >
          </v-text-field>
        </v-col>

        <v-spacer />
      </v-row>

      <v-data-table
        :headers="computedTableColumns"
        :items="listTable"
        :loading="loading"
        class="text-no-wrap"
        :loading-text="t('table.loading_text')"
        :no-data-text="t('table.no_data')"
        :header-props="{sortByText: t('table.sort_by')}"
        :footer-props="{itemsPerPageText: t('table.item_page'), 'items-per-page-options':[10, 15, 25, 50]}"
        :items-per-page="-1"
        :search.sync="searchQuery"
        :sort-desc.sync="descSort"
        :options.sync="options"
        :server-items-length="totalListTable"
      >
        <template #[`item.id`]="{item}">
          <router-link
            class="font-weight-medium text-decoration-none"
            :style="$vuetify.theme.dark ? 'color: #e7e3fcad' : '#3b0000de' "
            :to="{ name: 'views-payment-preview', params: { id: item.id, dataParams: item, pageParams: options, filterParams: filters } }"
          >
            #{{ item.id }}
          </router-link>
        </template>

        <template #[`item.payment_date`]="{item}">
          <span class="text-no-wrap">{{ item.payment_date }}</span>
        </template>

        <template #[`item.amount`]="{item}">
          <span class="text-no-wrap">{{ formatCurrency(item.amount) }}</span>
        </template>

        <template #[`item.status`]="{item}">
          <v-chip
            small
            :color="resolveClientAvatarVariant(item.status)"
            :class="`${resolveClientAvatarVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.status_str }}
          </v-chip>
        </template>

        <template #[`item.actions`]="{item}">
          <div class="d-flex align-center justify-center">
            <v-tooltip
              v-if="hasPermission(9)"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  color="info"
                  v-bind="attrs"
                  :to="{ name: 'views-fanaty-payment-preview', params: { id: item.id, dataParams: item, pageParams: options, filterParams: filters } }"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiEyeOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span>View Payment</span>
            </v-tooltip>

            <v-tooltip
              v-if="hasPermission(76)"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                  @click="onPrint(item)"
                >
                  <v-icon size="18">
                    {{ icons.mdiPrinter }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ t('tooltip.print_receivet') }}</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <vue-html2pdf
      v-show="!hideHtml2pdf"
      ref="html2Pdf"
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :enable-links="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="computedPaymentData.id ? `Receipt #${computedPaymentData.id} ${computedPaymentData.payment_date}` : 'Receipt'"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      :html-to-pdf-options="{
        html2canvas: {
          scrollX: 0,
          scrollY: 0,
        },
      }"
      @beforeDownload="beforeDownload()"
      @hasDownloaded="hasDownloaded()"
    >
      <section
        slot="pdf-content"
        style="background-color: #ffffff; width: 100%; height: 100%"
      >
        <payment-history-report
          :data-params="computedPaymentData"
          :has-transaction-video="hasTransactionVideo"
        ></payment-history-report>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import {
  mdiEyeOutline,
  mdiPrinter,
  mdiPlus,
} from '@mdi/js'

import { onMounted, ref, computed } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { info, success } from '@core/utils/toasted'
import { formatCurrency, useRouter } from '@core/utils'
import useCryptoJs from '@/@core/utils/useCryptoJs'
import exportExcel from '@core/utils/useExportExcel'

import VueHtml2pdf from 'vue-html2pdf'
import PaymentHistoryReport from '@/templates/reports/PaymentHistoryReport.vue'

import usePaymentList from './usePaymentList'

export default {
  components: {
    VueHtml2pdf,
    PaymentHistoryReport,
  },
  setup() {
    const { userData } = useCryptoJs()
    const { route, router } = useRouter()
    const {
      listTable,
      totalListTable,
      computedTableColumns,
      searchQuery,
      statusFilter,
      groupFilter,
      dateFrom,
      dateTo,
      maxDate,
      filters,
      options,
      loading,
      descSort,
      modalFrom,
      modalTo,

      t,
      resolveClientAvatarVariant,
      fetchPagedPayments,
      getDataBySearch,
      hasPermission,
      setFilters,
      setOptions,
      updateFilter,
      getFilterByModule,
    } = usePaymentList()

    const hideHtml2pdf = ref(true)
    const html2Pdf = ref(null)
    const initPaymentData = ref({
      id: null,
      amount: null,
      description: null,
      payment_date: null,
      status: null,
      status_str: null,
      user: {
        username: null,
        fullname: null,
      },
      group: {
        group_name: null,
        logo: null,
        email: null,
        phone: null,
      },
      payment_method: {
        id: null,
        owner_name: null,
        number: null,
        brand: null,
        billing_address: null,
        billing_email: null,
        billing_zip_code: null,
        group_id: null,
        billing_phone: null,
      },
      transactions: [],
    })
    const paymentData = ref(initPaymentData.value)
    const computedPaymentData = computed(() => paymentData.value)
    const hasTransactionVideo = computed(() => {
      if (paymentData.value) {
        return !paymentData.value.transactions.some(e => e.video === null)
      }

      return false
    })

    const statusOptions = computed(() => [
      { text: t('status.successful'), value: 'S' },
      { text: t('status.declined'), value: 'D' },
      { text: t('status.refunded'), value: 'R' },
    ])

    const generateReport = () => {
      html2Pdf.value.generatePdf()
    }

    const beforeDownload = () => {
      info('Generating PDF!')
    }

    const hasDownloaded = () => {
      success('PDF download successful!')
      paymentData.value = initPaymentData.value
    }

    const onPrint = item => {
      paymentData.value = item
      setTimeout(() => {
        generateReport()
      }, 1000)
    }

    const onCreate = () => {
      router.push({
        name: 'views-payment-form',
        params: {
          option: 1,
          pageParams: options.value,
          filterParams: filters.value,
        },
      })
    }

    onMounted(async () => {
      const { filterParams, pageParams } = route.value.params
      setOptions(pageParams)
      setFilters(filterParams)

      const filtersStored = getFilterByModule('views-fanaty-payment-list')
      if (filtersStored.status) statusFilter.value = filtersStored.status
      if (filtersStored.dateFrom) dateFrom.value = filtersStored.dateFrom
      if (filtersStored.dateTo) dateTo.value = filtersStored.dateTo
      if (filtersStored.search) searchQuery.value = filtersStored.search

      await getDataBySearch(300)

      setTimeout(() => {
        hideHtml2pdf.value = false
      }, 300)
    })

    return {
      searchQuery,
      statusOptions,
      statusFilter,
      groupFilter,
      dateFrom,
      dateTo,
      maxDate,
      filters,
      options,
      descSort,
      totalListTable,
      listTable,
      loading,
      userData,
      computedTableColumns,
      modalFrom,
      modalTo,

      hideHtml2pdf,
      html2Pdf,
      paymentData,
      computedPaymentData,
      hasTransactionVideo,

      // i18n
      t,

      avatarText,
      resolveClientAvatarVariant,
      formatCurrency,
      hasPermission,
      setFilters,
      setOptions,
      fetchPagedPayments,
      onPrint,
      generateReport,
      beforeDownload,
      hasDownloaded,
      onCreate,
      exportExcel,
      updateFilter,
      getFilterByModule,

      icons: {
        mdiEyeOutline,
        mdiPrinter,
        mdiPlus,
        export: require('@/assets/images/svg/export.svg'),
      },
    }
  },
}
</script>

<style lang="scss" scoped>
#payment-list {
  .payment-list-actions {
    max-width: 7.81rem;
  }
  .payment-list-search {
    max-width: 10.625rem;
  }
  .payment-list-status {
    max-width: 11.3rem;
  }
}
</style>
